import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { CustomLoader } from "../../utils/CommonLibrary";
import { fetchTemplates } from "../../../services/mtp/Service";
import CareCoordinateAnalytics from "./CareCoordinateAnalytics";
import CareCoordinatePendingTemplate from "./CareCoordinatePendingTemplate";
import CareCoordinateActiveTemplate from "./CareCoordinateActiveTemplate";
import CareCoordinateDeletedTemplates from "./CareCoordinateDeletedTemplates";

const CareCoordinateWhatsAppTabs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [templates, setTemplates] = useState([]);
  const [error, setError] = useState(null);
  const [lastSync, setLastSync] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get("tab");
    setSelectedIndex(tab ? parseInt(tab, 10) : 0);
  }, [location.search]);

  // New useEffect to handle template fetching when tab changes
  useEffect(() => {
    // Skip for Analytics tab (index 0)
    if (selectedIndex === 0) return;

    // Map tab indices to template statuses
    const statusMap = {
      1: "active2", // Active Templates tab
      2: "active3", // Pending Review tab
      3: "inactive", // Deleted tab
    };

    const status = statusMap[selectedIndex];
    if (status) {
      getTemplates(status, 1, 10); // Add appropriate page and limit values
    }
  }, [selectedIndex]);

  const handleTabSelect = (index) => {
    setSelectedIndex(index);
    navigate(`${location.pathname}?tab=${index}`, { replace: true });
  };

  const getTemplates = async (templateStatus, page, limit) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchTemplates(templateStatus, page, limit);
      setTemplates(response.data.data);
      setLastSync("");
    } catch (err) {
      setError("Error fetching templates. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="ContentCareNavigator">
      <div className="row align-items-center pb-3 sticky-top-bar">
        <div className="col-md">
          <h1 className="main-title">CareNavigator Management</h1>
        </div>
        <div className="col-md text-end"></div>
      </div>

      <div className="reports-programs mb-3">
        <div className="row align-items-center">
          <div className="col-md-auto">
            <img
              src="https://storage.googleapis.com/ksabupatest/2025/01/14/r4toj/qa8ehjwsr2.png"
              alt="Invited Members icon"
              style={{ height: "160px" }}
              className="img-fluid mb-0"
            />
          </div>
          <div className="col-md">
            <p className="mb-3">
            The WhatsApp Engagement module streamlines member communication by managing WhatsApp templates and tracking interactions between members and Care Navigators. It provides detailed analytics on message delivery, failures, responses, and average response times, ensuring efficient and effective communication while enhancing engagement insights.
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row align-items-center pb-3">
          <div className="comntent-CareNavigator mb-3">
            <div className="row">
              <div className="col-md-12">
                <Tabs
                  selectedIndex={selectedIndex}
                  onSelect={(index) => handleTabSelect(index)}
                >
                  <TabList>
                    <Tab>WhatsApp Analytics</Tab>
                    <Tab>Active Templates</Tab>
                    <Tab>Pending Review</Tab>
                    <Tab>Deleted</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="table-responsive CareNavigatorListTable">
                      <CareCoordinateAnalytics />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="table-responsive CapacityAssignmentTable">
                      <CareCoordinateActiveTemplate
                        templates={templates}
                        getTemplates={getTemplates}
                        lastSync={lastSync}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="table-responsive CareNavigatorAppointmentsTable">
                      <CareCoordinatePendingTemplate
                        templates={templates}
                        getTemplates={getTemplates}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="table-responsive">
                      <CareCoordinateDeletedTemplates templates={templates} />
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <CustomLoader />}
    </section>
  );
};

export default CareCoordinateWhatsAppTabs;
