import React, { useState,useEffect } from "react";
import moment from "moment";
import CalendarDatePicker from "../CalendarDatePicker";
import BackendDrivenDataTable from "../../cn/components/common/BackendDrivenDataTable";
import { decryptData } from "../../utils/Utils";
import { MultiSelect } from 'react-multi-select-component';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { exportTableData } from "../../utils/CommonLibrary";
import { carenavigatorWhatsAppOverview, carenavigatorWhatsappOverviewList, fetch_supervisors_list, getCareNavigators } from "../../../services/medengage/Service";

const sortData = (data, column, direction) => {
  return [...data].sort((a, b) => {
    const aValue = a[column];
    const bValue = b[column];

    // Handle numeric values
    if (!isNaN(aValue) && !isNaN(bValue)) {
      return direction === "asc"
        ? Number(aValue) - Number(bValue)
        : Number(bValue) - Number(aValue);
    }

    // Handle string values
    if (direction === "asc") {
      return aValue?.toString().localeCompare(bValue?.toString() || "");
    }
    return bValue?.toString().localeCompare(aValue?.toString() || "");
  });
};

const CareCoordinateAnalytics = () => {
  const [selectedDashboard, setSelectedDashboard] = useState("all");
  const [selectedSupervisor, setSelectedSupervisor] = useState("all");
  const [page, setPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("navigatorName");
  const [sortDirection, setSortDirection] = useState("asc");
  const [perPage, setPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [download, setDownload] = useState("N");

  const storedDates = localStorage.getItem("selectedDates")
    ? JSON.parse(decryptData(localStorage.getItem("selectedDates")))
    : "";

  const [startDate, setStartDate] = useState(
    storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment()
  );
  const [endDate, setEndDate] = useState(
    storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment()
  );

  const [carenavigatorsList, setCarenavigatorsList] = useState([]);
  const [supervisorList, setSupervisorList] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedCN, setSelectedCN] = useState("");
  const [whatsAppStats, setWhatsAppStats] = useState({
    totalMessagesSent: 0,
    totalMessagesDelivered: 0,
    totalMessagesRead: 0,
    totalMessagesUndelivered: 0,
    totalConversations:0,
    totalAvgResponseTime:0,
    pendingResponses:0,
    optOutMembers:0,

});

const [memberData, setMemberData] = useState([]);
  
  
  const handleDateChange = ({ start, end }) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleClear = () => {
    setSearchQuery("");
    setPage(1);
    setSortColumn("navigatorName");
    setSortDirection("asc");
    setSelectedDashboard("all");
    setSelectedSupervisor("all");
  };

 

useEffect(() => {
    getCareNavigatorsList();
}, [selectedSupervisor])

useEffect(() => {
    fetchSuperisorsList();
    fetchData();
    fetchMembersData();
}, []);


useEffect(() => {
  fetchData();
  fetchMembersData();
}, [startDate, endDate, selectedCN, selectedSupervisor]);

const fetchSuperisorsList = async () => {
  await fetch_supervisors_list()
      .then((res) => {
          setSupervisorList(res.data.data.supervisors);
      });
}

const getCareNavigatorsList = async () => {
  setOptions([]);
  setSelectedOptions([])
  await getCareNavigators(selectedSupervisor)
      .then((response) => {
          if (response.data.code === 200) {
              const carenavigators = response.data.data.users;
              setCarenavigatorsList(carenavigators);
              const formattedOptions = carenavigators.map((item) => ({
                  label: item.userName,
                  value: item.id
              }));
              setOptions(formattedOptions);
          }
      }).catch(function (error) {
          console.log(error);
      });
};

const handleChange = (selected) => {
  setSelectedOptions(selected);
  const valueArray = selected.map(option => option.value).join(',')

  setSelectedCN(valueArray);
};

const fetchData = async () => {
  await carenavigatorWhatsAppOverview(formatDate(startDate), formatDate(endDate), selectedCN, selectedSupervisor)
      .then((response) => {
          // const statsData = response.data.data.whatsAppData;
          setWhatsAppStats(prevState => ({
              ...prevState, totalMessagesSent: response.data.data.totalMessagesSent, totalMessagesDelivered: response.data.data.totalMessagesDelivered, totalMessagesRead: response.data.data.totalMessagesRead, totalMessagesUndelivered: response.data.data.totalMessagesUndelivered,totalConversations: response.data.data.totalConversations,pendingResponses: response.data.data.pendingResponses,totalAvgResponseTime: response.data.data.totalAvgResponseTime,optOutMembers: response.data.data.optOutMembers
          }));
      });
}

const fetchMembersData = async () => {
  await carenavigatorWhatsappOverviewList(formatDate(startDate), formatDate(endDate), selectedCN, selectedSupervisor)
      .then((response) => {
        if (response.data.code === 200) {
          setMemberData(response.data.data);
        }
      });
}

function formatDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}


  const analyticsData = [
    {
      title: "Total Messages Sent",
      value: whatsAppStats.totalMessagesSent,
      tooltip: "Total number of messages sent.",
    },
    {
      title: "Total Messages Delivered",
      value: whatsAppStats.totalMessagesDelivered,
      tooltip: "Total number of messages delivered.",
    },
    {
      title: "Total Messages Failed",
      value: whatsAppStats.totalMessagesUndelivered,
      tooltip: "Total number of messages failed.",
    },
    {
      title: "Total Messages Read",
      value: whatsAppStats.totalMessagesRead,
      tooltip: "Total number of messages read.",
    },
    {
      title: "Total Conversations",
      value: whatsAppStats.totalConversations,
      tooltip: "Total number of conversations.",
    },
    {
      title: "Pending Responses",
      value: whatsAppStats.pendingResponses,
      tooltip: "Total number of pending responses.",
    },
    {
      title: "Average Response Time",
      value: whatsAppStats.totalAvgResponseTime,
      tooltip: "Average response time.",
    },
    {
      title: "Opt-Out Members",
      value: whatsAppStats.optOutMembers,
      tooltip: "Total number of opt-out members.",
    },
  ];

  const columns = [
    {
      name: "CareNavigator Name",
      selector: (row) => row.carenavigatorName,
      sortable: true,
    },
    {
      name: "Supervisor",
      selector: (row) => row.supervisorName,
      sortable: true,
    },
    {
      name: "Messages Sent",
      selector: (row) => row.totalMessagesSent,
      sortable: true,
    },
    {
      name: "Messages Delivered",
      selector: (row) => row.totalMessagesDelivered,
      sortable: true,
    },
    {
      name: "Messages Read",
      selector: (row) => row.totalMessagesRead,
      sortable: true,
    },
    {
      name: "Messages Failed",
      selector: (row) => row.totalMessagesUndelivered,
      sortable: true,
    },
    {
      name: "Pending Responses",
      selector: (row) => row.pendingResponses,
      sortable: true,
    },
    {
      name: "Average Response Time",
      selector: (row) => row.totalAvgResponseTime,
      sortable: true,
    },
  ];


  const getFilteredAndSortedData = () => {
    // First apply search filter
    let filtered = memberData.filter((user) => {
      if (!searchQuery) return true;

      return (
        (user.carenavigatorName?.toLowerCase() || "").includes(
          searchQuery.toLowerCase()
        ) ||
        (user.supervisorName?.toLowerCase() || "").includes(
          searchQuery.toLowerCase()
        )
      );
    });

    // Then apply sorting
    return sortData(filtered, sortColumn, sortDirection);
  };

  const processedData = getFilteredAndSortedData();

  const handleExport = () => {
    const modifiedData = processedData.map((item) => {
      return {
        "Care Navigator Name": item.carenavigatorName,
        "Supervisor Name ": item.supervisorName,
        "Messages Sent": item.totalMessagesSent,
        "Messages Delivered": item.totalMessagesDelivered,
        "Messages Read": item.totalMessagesRead,
        "Messages Failed": item.totalMessagesUndelivered,
        "Pending Responses": item.pendingResponses,
        "Average Response Time": item.totalAvgResponseTime,
      };
    });

    modifiedData.forEach(function (v) {
      delete v.id;
    });
    exportTableData("CareNavigatorDetails", modifiedData);
  };

  return (
    <div>
      <div className="tw-w-full tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-4 tw-mb-3">
      <select className="form-select input-field-ui" id="selectedSupervisor" aria-label="Default select example" value={selectedSupervisor} onChange={(e) => setSelectedSupervisor(e.target.value)}>
          <option value="">Select Supervisor</option>
              {supervisorList.map(option => (
                <option key={option.id} value={option.id}>{option.name}</option>
              ))}
        </select>

        <MultiSelect
                            options={options}
                            value={selectedOptions}
                            onChange={handleChange}
                            labelledBy="Select"
                            hasSelectAll={false}
                            className="form-select select-input-ui select-input-ui-select"
                            disableSearch={true}
                            overrideStrings={{ selectAll: 'Select All', allItemsAreSelected: 'All items are selected', selectSomeItems: 'Select CareNavigators' }}
                            style={{ fontSize: '1rem' }}

            />
        
        <CalendarDatePicker
          startDate={startDate}
          endDate={endDate}
          onDateChange={handleDateChange}
        />
      </div>

      {/* <div className="data-not-bg mb-3">
        <div className="data-not-box">
          <div className="row text-center px-2">
            <div className="col text-center">
              <h1 class="data-not-title">
                Last Updated: January 9, 2025. Today's data will be available
                tomorrow.
              </h1>
            </div>
          </div>
        </div>
      </div> */}

      <div className="tw-grid tw-grid-cols-4 tw-gap-2 tw-bg-white tw-rounded-xl tw-shadow-md tw-text-center tw-p-2 tw-mb-3">
        {analyticsData.map((item, index) => (
          <div key={index} className="tw-px-1">
            <div className="tw-bg-[#f0f4ff] tw-rounded-[14px] tw-h-full">
              <div className="tw-p-2">
                <div className="tw-flex tw-flex-col">
                  <div className="tw-relative">
                    <h1 className="tw-text-2xl tw-font-bold tw-mb-1 tw-text-start tw-font-ibm-plex">
                      {item.value}
                    </h1>
                    <p className="tw-text-base tw-text-gray-500 tw-flex tw-items-center tw-justify-start">
                      {item.title}
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{item.tooltip}</Tooltip>}
                      >
                        <i className="bx bx-info-circle tw-ml-1 tw-cursor-pointer"></i>
                      </OverlayTrigger>
                    </p>
                    {/* <div className="tw-flex tw-flex-row tw-gap-1 tw-items-center tw-absolute tw-top-0 tw-right-0 tw-font-semibold">
                      <i
                        className={`tw-font-bold bi ${
                          graphScale
                            ? "bi-arrow-up-right tw-text-green-500"
                            : "bi-arrow-down-right tw-text-red-500"
                        }`}
                      ></i>
                      <p className="tw-mb-0 tw-text-gray-500">(Percentage)%</p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="tw-bg-white tw-rounded-xl tw-shadow-md tw-text-center tw-p-2 tw-mb-3">
        <div className="tw-flex tw-flex-row tw-items-center tw-justify-between mb-3">
          <div className=" tw-w-full">
            <div className="form-group" style={{ width: "40%" }}>
              <input
                type="text"
                placeholder="Search by CareNavigator or Supervisor Name"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="form-control input-field-ui ps-5"
              />
              <img
                src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png"
                className="input-icon-seacrh"
                alt=""
              />
            </div>
          </div>
          <div className="col-md-auto">
            <button
              onClick={handleClear}
              className="btn btn-primary btn-primary clear-search-btn"
            >
              Clear
            </button>
          </div>
          <div className="col-md-auto px-1">
            {processedData && (
              <button
                onClick={() => handleExport()}
                className="btn btn-primary btn-primary clear-search-btn"
              >
                <i className="bi bi-download"></i> Export
              </button>
            )}
          </div>
        </div>

        <BackendDrivenDataTable
          data={processedData}
          columns={columns}
          totalRows={processedData.length}
          perPage={perPage}
          page={page}
          setPage={setPage}
          setSortColumn={setSortColumn}
          setSortDirection={setSortDirection}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          setPerPage={setPerPage}
        />
      </div>
    </div>
  );
};

export default CareCoordinateAnalytics;
