import {configureStore} from '@reduxjs/toolkit';
import webexSlice from './webexSlice'; 
import memberSlice from './memberSlice';
import paginationSlice from './paginationSlice';
import filterSlice from './fitler/observationFitlerSlice'
import hccTicketFilterSlice from './fitler/hccTicketFilter';
import cnHccTicketFilterSlice from './fitler/cnHccTicketFilter';

const store = configureStore({
    reducer: {
        webex: webexSlice, 
        member: memberSlice, 
        pagination: paginationSlice, 
        filter: filterSlice, 
        hccTicketFilter: hccTicketFilterSlice, 
        cnHccTicketFilter: cnHccTicketFilterSlice
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});


export default store; 