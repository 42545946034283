import React, { useState, useEffect } from 'react'; 
import { useNavigate } from 'react-router-dom';

const AddTicketForm = ({ membershipNo, raisedBy, add_new_ticket, fetch_all_ticket_types, setShow=null}) => {

    const navigate = useNavigate();

    const [fileInputs, setFileInputs] = useState([{ id: Date.now() }]);
    const [ticketTypes, setTicketTypes] = useState([]);
    const [error, setError] = useState("");
    const [requestLoading, setRequestLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    // create state for the form bellow 
    const [ticketForm, setTicketForm] = useState({
        title: "",
        type: "",
        priority: "",
        files: [],
        description: "",
        membershipNo: membershipNo
    });

    const [ticketFormErrors, setTicketFormErrors] = useState({
        ticket: "",
        type: "",
        priority: "",
        files: "",
        description: "",
        membershipNo: ""
    });


    useEffect(() => { 
        fetchAllHccTicketTypes(); 
    }, []); 


    const addFileInput = () => {
        setFileInputs([...fileInputs, { id: Date.now() }]);
    };


    async function handleTicketInfoChange(e) {

        if (e.target.name === 'files[]') {
            const inputId = parseInt(e.target.getAttribute('data-input-id'));
            const inputIndex = fileInputs.findIndex(input => input.id === inputId);
            const updatedFiles = [...ticketForm.files];

            let reader = new FileReader();

            let file = e.target.files[0];

            let fileInfo = { size: file.size, name: file.name, extension: file.name.split('.').pop() };

            reader.readAsDataURL(e.target.files[0]);
            reader.onloadend = (e) => {
                updatedFiles[inputIndex] = { file: e.target.result, ...fileInfo };
                setTicketForm({ ...ticketForm, files: updatedFiles });
                setTicketFormErrors({
                    ...ticketFormErrors,
                    files: ""
                });
            };
            return;
        }


        setTicketForm({ ...ticketForm, [e.target.name]: e.target.value });
        setTicketFormErrors({
            ...ticketFormErrors,
            [e.target.name]: ""
        });
    }


     async function fetchAllHccTicketTypes (){
          try{ 
            const response = await fetch_all_ticket_types();
    
            if(response.data.code === 200){ 
              const data = response.data?.data?.ticketTypes;  
              const ticketTypes = data.map(ticketType => ({ value: ticketType.id, label: ticketType.ticket_type }));
              setTicketTypes(ticketTypes); 
            }
          }catch(e){
            const errorResponse = e?.response?.data;
    
            if(errorResponse?.code < 500){
              setError(errorResponse.data.message || "Something went wrong");
            }else { 
              setError("Something went wrong");
            }
          }
      }


    function removeFileInput(inputId) {
        if (fileInputs.length > 1) {
            // Keep existing multiple input removal logic
            setFileInputs(fileInputs.filter(input => input.id !== inputId));

            const updatedFiles = [...ticketForm.files];
            const inputIndex = fileInputs.findIndex(input => input.id === inputId);
            if (inputIndex !== -1) {
                updatedFiles.splice(inputIndex, 1);
                setTicketForm({ ...ticketForm, files: updatedFiles });
            }
        } else {
            // For single input, clear the file and reset the input
            setTicketForm({ ...ticketForm, files: [] });

            // Reset the file input element
            const fileInput = document.querySelector(`input[data-input-id="${inputId}"]`);
            if (fileInput) {
                fileInput.value = '';
            }
        }
    }



    function validationTicketForm() {
        const { title, type, priority, files, description } = ticketForm;
        console.log(files);
        let errors = {};

        if (title === "") {
            errors.title = "Title is required";
        } else if (title.length > 300) {
            errors.title = "Title must be less than 300 characters";
        }

        if (type === "") {
            errors.type = "Type is required";
        }

        if (priority === "") {
            errors.priority = "Priority is required";
        }

        if (ticketForm?.files) {
            files.forEach(file => {
                if (file?.size > 10000000) {
                    errors.files = "File size is too large. Max size is 10MB";
                }

                const allowedExtensions = ['pdf', 'jpeg', 'jpg', 'png', 'mp4', 'tiff', 'doc', 'docx', 'xls', 'xlsx', 'csv', 'txt', 'dcm', 'mov', 'gif'];
                const fileExtension = file?.extension;
                if (!allowedExtensions.includes(fileExtension)) {
                    errors.files = 'Invalid file type. Only PDF, JPEG, JPG, PNG, MP4, MOV, TIFF, DOC, DOCX, XLS, XLSX, CSV, TXT, and DCM files are allowed';
                };
            })
        }

        if (description === "") {
            errors.description = "Description is required";
        }

        return errors;
    }



    const submitTicket = async (e) => {

        e.preventDefault();

        // validate the form
        let errors = validationTicketForm();
        if (Object.keys(errors).length > 0) {
            setTicketFormErrors(errors);
            return;
        }
        setRequestLoading(true);
        // make an api call to store the data   
        let requestData = {...ticketForm, files: JSON.stringify(ticketForm.files)}
        
        try {
            const response = await add_new_ticket(requestData);

            if (response.data?.code === 200) {
                setMessage(response.data?.data?.message);

                if(raisedBy === "hcc_agent"){
                    navigate('/all-tickets');
                }else { 
                    setTimeout(() => { 
                        setShow(false);    
                    }, 1000); 
                }

                setTimeout(() => {
                    setTicketForm({
                        title: "",
                        type: "",
                        priority: "",
                        files: [],
                        description: "",
                        membershipNo: ""
                    });
                    setMessage("");
                }, 2000);
            }
        } catch (e) {
            const errorResponse = e?.response?.data;
            if (errorResponse?.code < 500) {
                setError(errorResponse?.data?.message);
            } else {
                setError(errorResponse?.data?.message);
            }
        } finally {
            setTimeout(() => {
                setRequestLoading(false);
            }, 2000);
        }
    }

    function handleClear(){ 
        setTicketForm({
          title: "", 
          type: "", 
          priority: "", 
          files: [],
          description: "", 
          membershipNo: ""
        });
        setTicketFormErrors({ 
          title: "", 
          type: "", 
          priority: "", 
          files: "", 
          description: "", 
          membershipNo: ""
        });
      }


    return (
        <div className="add-hcc-ticket-form">
            <form>
                <div style={{ padding: '1rem', }}>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 px-4">
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="title" className="form-label">
                                        Title
                                        <span style={{ color: 'red' }}>&nbsp;*</span>
                                    </label>
                                    <div style={{ display: 'flex', }}>
                                        <input
                                            type="text"
                                            id="ticketTitle"
                                            name="title"
                                            placeholder="Enter a concise title summarizing the Ticket"
                                            style={{ padding: '0.5rem', marginRight: '0.5rem', border: '1px solid #ccc', borderRadius: '0.25rem', width: '100%' }}
                                            value={ticketForm.title}
                                            onChange={(e) => handleTicketInfoChange(e)}
                                        />
                                    </div>
                                    <div className='invalid-input'>{ticketFormErrors.title}</div>

                                </div>

                                <div className="col-md-6 mb-3">
                                    <label htmlFor="ticketType" className="form-label">
                                        Ticket Type
                                        <span style={{ color: 'red' }}>&nbsp;*</span>
                                    </label>
                                    <div style={{ display: 'flex', }}>
                                        <select
                                            id="ticketType"
                                            style={{ padding: '0.5rem', marginRight: '0.5rem', border: '1px solid #ccc', borderRadius: '0.25rem', width: '100%' }}
                                            name="type"
                                            value={ticketForm.type}
                                            onChange={(e) => handleTicketInfoChange(e)}
                                        >
                                            <option value="">Select Ticket Type</option>
                                            {
                                                ticketTypes.length > 0 ?
                                                    ticketTypes.map((ticketType) => (
                                                        <option key={ticketType.value} value={ticketType.value}>
                                                            {ticketType.label}
                                                        </option>
                                                    )) : null
                                            }
                                        </select>
                                    </div>
                                    <div className='invalid-input'>{ticketFormErrors.type}</div>
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label htmlFor="priority" className="form-label">
                                        Priority
                                        <span style={{ color: 'red' }}>&nbsp;*</span>
                                    </label>
                                    <div style={{ display: 'flex', }}>
                                        <select
                                            id="priority"
                                            style={{ padding: '0.5rem', marginRight: '0.5rem', border: '1px solid #ccc', borderRadius: '0.25rem', width: '100%' }}
                                            value={ticketForm.priority}
                                            name="priority"
                                            onChange={(e) => handleTicketInfoChange(e)}
                                        >
                                            <option value="">Select Priority</option>
                                            <option value="blocker">Blocker</option>
                                            <option value="major">Major</option>
                                            <option value="minor">Minor</option>
                                            <option value="good_to_have">Good-to-have</option>
                                        </select>
                                    </div>
                                    <div className='invalid-input'>{ticketFormErrors.priority}</div>
                                </div>

                                <div className="col-md-12 mb-3">
                                    <label htmlFor="files" className="form-label">
                                        Attach screenshots or data that illustrate the Ticket (The maximum allowed file upload size is 10 MB)
                                    </label>
                                    {
                                        fileInputs.map((input) => {
                                            const hasFile = ticketForm.files[fileInputs.findIndex(f => f.id === input.id)];

                                            return (
                                                
                                                    <div className='tw-flex tw-w-full tw-mb-3' key={input.id}>
                                                        <input
                                                            type="file"
                                                            name="files[]"
                                                            accept="image/*,video/*"
                                                            className='form-control input-field-ui form-control-sm'
                                                            data-input-id={input.id}
                                                            onChange={e => handleTicketInfoChange(e)}
                                                    />

                                                    {(fileInputs.length > 1 || (fileInputs.length === 1 && hasFile)) && (
                                                        <button
                                                            type="button"
                                                            className="btn btn-Cancel ms-2"
                                                            onClick={() => removeFileInput(input.id)}
                                                        >
                                                            <i className="bi bi-trash"></i>
                                                        </button>
                                                    )}
                                                </div>
                                               
                                            )
                                        })
                                    }
                                    <button
                                        type="button"
                                        className='btn btn-primary addNewRow-btn me-2'
                                        onClick={addFileInput}
                                    >
                                        <i className="bi bi-plus"></i> Add More Files
                                    </button>
                                    <div className='invalid-input'>{ticketFormErrors.files}</div>
                                </div>

                                <div className="col-md-12 mb-3">
                                    <label htmlFor="description" className="form-label">
                                        Description
                                        <span style={{ color: 'red' }}>&nbsp;*</span>
                                    </label>
                                    <div className="vertical-display">

                                        <textarea
                                            id="description"
                                            style={{
                                                resize: 'none',
                                                width: '100%',
                                                height: '150px',
                                                padding: '0.5rem',
                                                marginRight: '0.5rem',
                                                border: '1px solid #ccc',
                                                borderRadius: '0.25rem',
                                            }}
                                            placeholder="Describe the ticket in detail, including expected behavior and steps to reproduce it."
                                            name="description"
                                            value={ticketForm.description}
                                            onChange={(e) => handleTicketInfoChange(e)}
                                        ></textarea>
                                    </div>
                                    <div className='invalid-input'>{ticketFormErrors.description}</div>
                                </div>

                                {
                                    (message && message.length > 0) &&
                                    <div className='text-center'>
                                        <p className="text-success">{message}</p>
                                    </div>
                                }

                                {
                                    (error && error.length > 0) &&
                                    <div className='text-center'>
                                        <p className="text-danger">{error}</p>
                                    </div>
                                }

                                <div className="col-md-12 mb-3">
                                    <button
                                        className="btn btn-primary save-btn"
                                        type="submit"
                                        onClick={e => submitTicket(e)}
                                        disabled={requestLoading}
                                    >
                                        Submit
                                    </button>
                                    <button
                                        className="btn btn-secondary clear-btn tw-ml-3"
                                        type="reset"
                                        onClick={e => handleClear(e)}
                                    >
                                        Clear
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddTicketForm; 