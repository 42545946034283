import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import BackendDrivenDataTable from '../../cn/components/common/BackendDrivenDataTable';
import { fetch_contract_priority_info, edit_contract_priority, fetch_contract_priority_list, remove_contract_priority, add_contract_priority, fetch_contract_details } from '../../../services/medengage/Service';
import { validateFormContractPriorityAdd } from '../../utils/validationUtils';
import { CustomLoader } from '../../utils/CommonLibrary';

const ContractPriority = forwardRef((props, ref) => {

    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState("contractNumber");
    const [sortDirection, setSortDirection] = useState('asc');

    const [isNewLoading, setIsNewLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [selectedService, setSelectedService] = useState('All');
    const [passData, setPassData] = useState([]);
    const [selectedData, setSelectedData] = useState('');
    const [isLoading, setIsLoading] = useState('');
    const [editId, setEditId] = useState('');
    const [deleteId, setDeleteId] = useState('');
    const [dispositionDataList, setDispositionDataList] = useState([]);
    const [editShowModal, setEditShowModal] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState('');
    const handleClose = () => { setEditShowModal(false); }
    const [showModal, setShowModal] = useState(false);
    const handleAddClose = () => { setShowModal(false); }
    const [deleteShowModal, setDeleteShowModal] = useState(false);
    const handleDeleteClose = () => { setDeleteShowModal(false); }

    const [addCPContractName, setAddCPContractName] = useState("");
    const [addCPMemberCount, setAddCPMemberCount] = useState("");

    const [editCPContractName, setEditCPContractName] = useState("");
    const [editCPMemberCount, setEditCPMemberCount] = useState("");

    const [formData, setFormData] = useState({
        contractNumber: '',
        priority: '',
    })

    const [errors, setErrors] = useState({
        contractNumber: '',
        priority: '',
    })

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        fetchData();
    }, [page, perPage, sortColumn, sortDirection, searchQuery]);


    const fetchData = async () => {
        //setIsLoading(true);
        try {
            let resp = await fetch_contract_priority_list(searchQuery, page, perPage, sortColumn, sortDirection);
            if (resp.data.code == 200) {
                setPassData(resp.data.data.contracts);
                setTotalRows(resp.data.data.totalRows);
            }
        } catch (error) {
            console.error(error);
        } finally {
            //setIsLoading(false); // Will be called regardless of success or failure
        }

    }

    const fetchContractDetails = async () => {
        //setIsLoading(true);
        try {
            let resp = await fetch_contract_details(formData['contractNumber']);
            if (resp.data.code == 200) {
                setAddCPContractName(resp.data.data.contractName);
                setAddCPMemberCount(resp.data.data.memberCount);
            } else {
                setErrors({
                    contractNumber: resp.data.data.message,
                    priority: ''
                })
            }
        } catch (error) {
            console.error(error);
        } finally {
            //setIsLoading(false); // Will be called regardless of success or failure
        }

    }

    const columns = [
        {
            name: 'Contract Number',
            selector: row => row.contractNumber,
            sortable: true,
        },
        {
            name: 'Contract Name',
            selector: row => row.contractName,
            sortable: false,

        },
        {
            name: 'Priority',
            selector: row => row.priority,
            sortable: true,

        },
        {
            name: 'Active Members',
            selector: row => row.memberCount,
            sortable: false,

        },
        {
            name: 'Action',
            cell: row => (
                <div><button onClick={() => {
                    setErrors('');
                    setShowEditModalOpen(row.id);
                }} class="btn btn-Edit"><i className="bi bi-pencil"></i></button> | <button onClick={() => setShowDeleteModalOpen(row.id)} class="btn btn-Cancel"><i className="bi bi-trash"></i></button></div>
            ),
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedCategory('All');
    };

    const setShowEditModalOpen = async (id) => {
        setEditId(id);
        setErrors('');
        setEditCPContractName("");
        setEditCPMemberCount("");
        try {
            let resp = await fetch_contract_priority_info(id);
            if (resp.data.code == 200) {
                //setFormData(resp.data.data[0]);
                setFormData({
                    contractNumber: resp.data.data[0]['contractNumber'],
                    priority: resp.data.data[0]['priority']
                });
                setEditCPContractName(resp.data.data[0]['contractName']);
                setEditCPMemberCount(resp.data.data[0]['memberCount']);
            } else {
                setFormData(resp.data.data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            //setIsLoading(false); // Will be called regardless of success or failure
        }
        setEditShowModal(true);

    }
    const setShowDeleteModalOpen = async (id) => {
        setDeleteId(id);
        setDeleteShowModal(true);
    };
    const submitDeleteDisposition = async (deleteId) => {

        try {
            let resp = await remove_contract_priority(deleteId);
            if (resp.data.code == 200) {
                setDeleteMessage(resp.data.data.message);
                setDeleteShowModal(false);
                fetchData();
            }
        } catch (error) {
            console.error(error);
        } finally {
            //setIsLoading(false); // Will be called regardless of success or failure
        }


    }

    const handleChange = (event) => {
        setFormData((prevData) => ({ ...prevData, ['contractNumber']: event.target.value }));
    };


    const editNewContractPriority = async (e) => {
        e.preventDefault();
        setErrors('')

        const validationErrors = validateFormContractPriorityAdd(formData);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            const combinedData = {
                ...formData,
                id: editId
            };
            edit_contract_priority(combinedData)
                .then((response) => {
                    if (response.data.code == 200) {
                        setEditShowModal(false);
                        fetchData();

                    }
                });
        }
    }

    const onValueChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const setShowModalOpen = (e) => {
        setFormData('');
        setShowModal(true);

    }

    const addNewContractPriority = async (e) => {
        e.preventDefault();
        setErrors('')

        const validationErrors = validateFormContractPriorityAdd(formData);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            add_contract_priority(formData)
                .then((response) => {
                    if (response.data.code == 200) {
                        setShowModal(false);
                        fetchData();
                    } else {
                        setErrors({
                            contractNumber: response.data.data.message
                        })
                    }
                });
        }
    }

    const handleSearch = () => {

    }

    return (
        <>
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md-4 d-flex px-1'>
                        <div className="form-group" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search By Contract Number or Priority"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-4 px-1'>
                        <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                    </div>
                    <div className='col-md-4 px-1' style={{ textAlign: 'right' }}>
                        <button onClick={setShowModalOpen} className="btn btn-primary add-new-program-btn">
                            <i className="bi bi-card-checklist me-2"></i> Add New Contract Priority
                        </button>
                    </div>

                </div>
            </div>

            {
                isNewLoading ? <CustomLoader /> :
                    <BackendDrivenDataTable
                        data={passData}
                        loading={isNewLoading}
                        totalRows={totalRows}
                        page={page}
                        perPage={perPage}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        filterText={searchQuery}
                        columns={columns}
                        fetchTableData={fetchData}
                        setPage={setPage}
                        setSortColumn={setSortColumn}
                        setSortDirection={setSortDirection}
                        setPerPage={setPerPage}
                    />
            }

            {editShowModal &&
                <div className="modal fade show" id="addNewServiceModal" tabIndex="-1" aria-hidden="false" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="main-title">Edit Contract Priority</h4>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={handleClose}
                                ></button>
                            </div>
                            <div class="modal-body pt-1 mb-2">
                                <form className='' onSubmit={editNewContractPriority}>
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor='Condition' class="form-label">Contract Number</label>
                                            <span style={{ color: 'red' }}> *</span>
                                            <input type="text"
                                                readonly="true"
                                                className="form-control input-field-ui"
                                                placeholder="Contract Number"
                                                rows="3"
                                                name="contractNumber"
                                                onChange={(e) => onValueChange(e)} value={formData['contractNumber']}
                                            />
                                            {errors['contractNumber'] && <div className="text-danger">{errors['contractNumber']}</div>}
                                        </div>
                                        {
                                            editCPContractName != "" &&
                                            <div className="col-md-12 mb-3">
                                                <span>Contract Name: {editCPContractName}</span>
                                            </div>
                                        }
                                        {
                                            editCPMemberCount != "" &&
                                            <div className="col-md-12 mb-3">
                                                <span>Active Members: {editCPMemberCount}</span>
                                            </div>
                                        }
                                        {
                                            editCPContractName != "" &&
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor='Condition' class="form-label">Priority </label>
                                                <span style={{ color: 'red' }}> *</span>
                                                <select class="form-select input-field-ui" name="priority" aria-label="Default select example" value={formData['priority']} onChange={(e) => onValueChange(e)}>
                                                    <option value="">Select</option>
                                                    <option value="high">Critical Priority</option>
                                                    <option value="medium">Urgent Priority</option>
                                                    <option value="low">Moderate Priority</option>
                                                </select>
                                                {(errors['priority']) && <div className="text-danger">{errors['priority']}</div>}
                                            </div>

                                        }


                                        <div className="col-md-12 my-2 text-center">
                                            <button
                                                type="submit"
                                                className="btn btn-primary program-save-btn me-2"
                                            >
                                                Save Contract Priority
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>

                </div>}



            {deleteShowModal && (
                <div className="modal fade show" id="deleteConfirmationModal" tabIndex="-1" aria-hidden="false" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h5 className="modal-title fw-semibold">Delete Confirmation</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={handleDeleteClose}
                                    aria-label="Close"
                                ></button>
                            </div>

                            {/* Modal Body */}
                            <div className="modal-body py-4">
                                <p className="text-center mb-0">
                                    Are you sure you want to delete this contract priority?
                                </p>
                            </div>

                            {/* Modal Footer */}
                            <div className="tw-flex tw-justify-center tw-py-4">
                                {/* <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={handleDeleteClose}
                                >
                                    Cancel
                                </button> */}
                                <button
                                    type="button"
                                    className="btn btn-primary program-save-btn me-2"
                                    onClick={() => submitDeleteDisposition(deleteId)}
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Modal Backdrop */}
                    <div className="modal-backdrop fade show"></div>
                </div>
            )}

            {
                showModal &&
                <div className="modal fade show" id="addNewContractPriorityModal" tabIndex="-1" aria-hidden="false" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="main-title">Add New Contract Priority</h4>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={handleAddClose}
                                ></button>
                            </div>
                            <div class="modal-body pt-1 mb-2">
                                <form className='' onSubmit={addNewContractPriority}>
                                    <div className="row">
                                        <div className="col-md-8 mb-3">
                                            <label htmlFor='Condition' class="form-label">Contract Number </label>
                                            <span style={{ color: 'red' }}> *</span>
                                            <input type="text"
                                                className="form-control input-field-ui"
                                                placeholder="Contract Number"
                                                rows="3"
                                                name="contractNumber"
                                                onChange={(e) => {
                                                    onValueChange(e);
                                                    setAddCPContractName("");
                                                    setAddCPMemberCount("");
                                                    setErrors('');
                                                }} value={formData['contractNumber']}
                                            />

                                            {errors['contractNumber'] && <div className="text-danger">{errors['contractNumber']}</div>}
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <button type="button" onClick={fetchContractDetails} className="btn btn-primary add-new-program-btn">
                                                <i className="bi bi-search me-2"></i> Search
                                            </button>
                                        </div>
                                        {
                                            addCPContractName != "" &&
                                            <div className="col-md-12 mb-3">
                                                <span>Contract Name: {addCPContractName}</span>
                                            </div>
                                        }
                                        {
                                            addCPMemberCount != "" &&
                                            <div className="col-md-12 mb-3">
                                                <span>Active Members: {addCPMemberCount}</span>
                                            </div>
                                        }
                                        {
                                            addCPContractName != "" &&
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor='Condition' class="form-label">Priority {formData['contractName']}</label>
                                                <span style={{ color: 'red' }}> *</span>
                                                <select class="form-select input-field-ui" name="priority" aria-label="Default select example" value={formData['priority']} onChange={(e) => onValueChange(e)}>
                                                    <option value="">Select</option>
                                                    <option value="high">Critical Priority</option>
                                                    <option value="medium">Urgent Priority</option>
                                                    <option value="low">Moderate Priority</option>
                                                </select>
                                                {(errors['priority']) && <div className="text-danger">{errors['priority']}</div>}
                                            </div>
                                        }
                                        {
                                            formData['priority'] != "" && formData['priority'] != undefined &&
                                            <div className="col-md-12 my-2 text-center">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary program-save-btn me-2"
                                                >
                                                    Add Contract Priority
                                                </button>
                                            </div>
                                        }

                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            }
        </>


    );
});

export default ContractPriority