import React, { useState } from 'react';

const LogModal = ({isOpen,viewHistoryData, onClose,viewHistoryApprovedData }) => { 
const timelineData = viewHistoryData
const historyApprovedData = viewHistoryApprovedData

console.log("dfdd",viewHistoryData);

  //const [timelineData, setTimelineData] = useState(data);

  // Dummy data matching the screenshot
 /* const timelineData = [
    {
      date: '07 Aug, 2024',
      name: 'Paresh',
      role: 'Template Coordinator',
      status: 'Submitted',
      comments: 'Please review template for program information'
    },
    {
      date: '07 Aug, 2024',
      name: 'Sachin',
      role: 'Template Coordinator',
      status: 'Rejected',
      comments: 'Please change video and add new video with latest event details.'
    },
    {
      date: '08 Aug, 2024',
      name: 'Paresh',
      role: 'Template Coordinator',
      status: 'Submitted',
      comments: 'Updated Template with suggested changes'
    },
    {
      date: '07 Aug, 2024',
      name: 'Sachin',
      role: 'Template Coordinator',
      status: 'Approved',
      comments: 'Submitted to Meta'
    },
    {
      date: '07 Aug, 2024',
      name: 'Meta',
      role: '',
      status: 'Approved',
      comments: '',
      category: 'MARKETING',
      isFinal: true
    }
  ];*/

  if (!isOpen) return null;

  return (
    <div className="tw-fixed tw-inset-0 tw-z-50 tw-overflow-y-auto">
      {/* Overlay */}
      <div 
        className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-transition-opacity"
        onClick={onClose}
      />
      
      {/* Modal */}
      <div className="tw-fixed tw-inset-0 tw-z-50 tw-overflow-y-auto">
        <div className="tw-flex tw-min-h-full tw-items-center tw-justify-center tw-p-4">
          <div 
            className="tw-relative tw-w-full tw-max-w-2xl tw-max-h-[80vh] tw-transform tw-overflow-y-auto tw-rounded-lg tw-bg-white tw-p- tw-text-left tw-shadow-xl tw-transition-all"
            onClick={(e) => e.stopPropagation()}
          >
            <div className='tw-sticky tw-top-0 tw-bg-white tw-z-10 tw-px-6 tw-py-4 tw-border-b'>
            {/* Close button */}
            <span
              onClick={onClose}
              className="tw-absolute tw-right-4 tw-top-4 tw-text-gray-400 hover:tw-text-gray-500 tw-cursor-pointer"
            >
              <span className="tw-sr-only">Close</span>
              <svg 
                className="tw-h-6 tw-w-6" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M6 18L18 6M6 6l12 12" 
                />
              </svg>
            </span>

            {/* Template Name */}
            <div className="tw-mb-6">
              <h4 className="tw-text-gray-500">Template Name: <span className="tw-text-gray-700">{timelineData[0].templateName}</span></h4>
            </div>
            </div>

            {/* Timeline */}
            <div className="tw-relative tw-mt-2 tw-p-6">
            {timelineData?.map((item, index) => (
  <div key={index} className="tw-relative tw-pb-8">
    {/* Timeline line */}
    {index !== timelineData.length - 1 && (
      <div className="tw-absolute tw-left-[34%] tw-top-6 tw-h-full tw-w-1 tw-bg-green-200"></div>
    )}

    {/* Timeline dot */}
    <div className="tw-absolute tw-left-[32%] tw-top-0 tw-h-7 tw-w-7 tw-rounded-full tw-bg-green-200 tw-flex tw-items-center tw-justify-center">
      <div className="tw-h-3 tw-w-3 tw-rounded-full tw-bg-green-500"></div>
    </div>

    {/* Content */}
    <div className="tw-flex tw-flex-row  tw-justify-between tw-gap-16">
      <div className='tw-flex tw-flex-col tw-text-right tw-w-[30%]'>
      <div className="tw-mb-1">
        <span className="tw-text-gray-600">{item.createdTime}</span>
      </div>
      <div className="tw-mb-1 tw-text-sm">
        {item.status === 'Approved' ? (
          <>
          <span className="tw-text-green-600">Approved by: </span>
          <span className="tw-text-gray-700">Meta</span>
          </>

        ) : (
          <>
          <span className="tw-text-gray-500">Submitted by: </span>
          <span className="tw-text-gray-700">{item.fullName}</span>
          <br/>
          <span className="tw-text-gray-500">Status: </span>
          <span className="tw-text-gray-700">{item.approvedStatus}</span>
          </>
        )}
        
      </div>
      {item.logStatus?.map((logrole, index) => (
      logrole.createdBy && (
        <div className="tw-text-sm tw-text-gray-500">
          Role: {logrole.createdBy}
        </div>
      ) ))}
      </div>
      <div className='tw-flex tw-flex-col tw-mt-1 tw-w-[65%]'>
      {item.uploaderComment && (
        <div className="tw-p-3 tw-rounded-xl border tw-border-gray-100 tw-shadow-lg">
          <div className="tw-text-sm tw-text-gray-500">Comments:</div>
          <div className="tw-text-gray-700">{item.uploaderComment}</div>
        </div>
      )}
      {/* Additional Content for the Last Entry */}
    {item.isFinal && item.metaCategory  && (
      <div className="tw-px-3 tw-pb-1 tw-rounded-xl tw-border-gray-100 tw-shadow-lg">
        <div className="tw-text-sm">
          <span className="tw-text-gray-500">Status : </span>
          <span className="tw-text-green-600">Approved</span>
        </div>
        <div className="tw-text-sm">
          <span className="tw-text-gray-500">Meta Category: </span>
          <span className="tw-text-gray-700">{item.metaCategory}</span>
        </div>
      </div>
    )}
      </div>
    </div>

    
  </div>
))}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogModal;