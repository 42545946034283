import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { add_new_ticket, fetch_all_ticket_types, list_all_hcc_tickets, fetch_single_ticket, fetch_ticket_thread, comment_on_ticket, update_ticket, fetch_ticket_history } from '../../services/Service';
import ServerSideSortableDataTable from '../../Tables/ServerSideSortableDataTable';
import './hccticketmodal.css';
import { Link } from 'react-router-dom';
import AddTicketForm from '../../../../hcc/Components/AddTicketForm/AddTicketForm';
import TicketDetails from '../../../../hcc/Components/TicketDetails/TicketDetails';
import CommentForm from '../../../../hcc/Components/CommentForm/CommentForm';
import { LoaderLoader } from '../../../../utils/CommonLibrary';
import { capitalizeFirstLetter } from '../../../../utils/Utils';
import { Download, File, FileText, Image, Video } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { setFilter } from '../../../../../store/fitler/cnHccTicketFilter';
import moment from 'moment';

const HccTicketModal = ({ show, setShow, access, defaultMode, membershipNo }) => {

  const [ticket,setTicket] = useState({});
  const [modalContentType, setModalContentType] = useState(defaultMode); // only Three possible values 1. add 2. display 3. viewSingle
  const [ticketId, setTicketId] = useState("");
  const [tickets, setTickets] = useState([]);
  const [sortConfig, setSortConfig] = useState({ field: "", direction: "" });
  const [paginationInfo, setPaginationInfo] = useState({ perPage: 7, totalPages: 0, currentPage: 1, totalRecords: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [ticketThread, setTicketThread] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); 
  const [ticketTypes, setTicketTypes] = useState([]);
  const [commentForm, setCommentForm] = useState({
    comment: "", 
    files: ""
  });

  const [ticketHistory, setTicketHistory] = useState([]); 
  const [commentFormErrors, setCommentFormErrors]= useState({ 
    comment: "", 
    files: ""
  });
  
  const [fileInputs, setFileInputs] = useState([{ id: 0 }]);
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch(); 
  const filter = useSelector(state => state.cnHccTicketFilter.filter);

  const {searchTicketId,status, startDate, endDate} = filter; 

  const [columns] = useState([
    {
      name: (
        <span>Ticket ID</span>
      ),
      selector: 'ticket_id',
      sortable: true,
    },
    {
      name: (
        <span>Title</span>
      ),
      selector: 'title',
      sortable: false,
      maxWidth: '500px'
    },
    {
      name: (
        <span>Status</span>
      ),
      selector: 'status',
      sortable: true,
    },
    {
      name: (
        <span>Priority</span>
      ),
      selector: 'priority',
      sortable: true,
    },
    {
      name: (
        <span>Ticket Type</span>
      ),
      selector: 'ticketType',
      sortable: true,
    },
    {
      name: (
        <span>Raised On Date</span>
      ),
      selector: 'createdTime',
      sortable: true,
    },
    {
      name: (
        <span>Last Action Date</span>
      ),
      selector: 'lastActionTime',
      sortable: true,
    },
    {
      name: (
        <span>Raised By</span>
      ),
      selector: 'raisedBy',
      sortable: true,
    },

    {
      name: (
        <span>Options</span>
      ),
      cell: row => (
        <div>
          <button style={{ textDecoration: 'none', border: "none", backgroundColor: 'transparent' }} onClick={() => handleShowSingleTicket(row.encTicketId)} className='mb-0 me-2 tw-text-black'>
            <i className="fa fa-eye"></i>
          </button>
        </div>
      ),
    }
  ]);

  useEffect(() => {
    fetchAllTickets();
  }, [sortConfig.field, sortConfig.direction, paginationInfo.perPage, paginationInfo.currentPage, searchTicketId, status, startDate, endDate]);

  useEffect(() => {
    if (modalContentType === "viewSingle") {
      fetchCommentThread();
      fetchTicketHistory(); 
    }
  }, [modalContentType]); 

  useEffect(() => { 
     let timer = setTimeout(() => {
       dispatch(setFilter({...filter, searchTicketId: searchQuery}));
     }, 500);

     return () => { 
       clearTimeout(timer);
     }
  }, [searchQuery]);


  useEffect(() => { 
    fetchAllTicketTypes(); 
  }, []); 


  
  function handleCommentChange(e) {
  
    if(e.target.name === 'files[]') {
      const inputId = parseInt(e.target.getAttribute('data-input-id'));
        const updatedFiles = Array.from(commentForm.files || []);
        updatedFiles[inputId] = e.target.files[0];
        const filteredFiles = updatedFiles.filter(file => file !== null);

      let reader = new FileReader();

      let file = e.target.files[0];

      let fileInfo = { size: file.size, name: file.name, extension: file.name.split('.').pop() };

      reader.readAsDataURL(e.target.files[0]);
      reader.onloadend = (e) => {
          setCommentForm({ ...commentForm, files: filteredFiles });
          setCommentFormErrors({
              ...commentFormErrors,
              files: ""
          });
      };
      return;
    }
  
    setCommentForm({ ...commentForm, [e.target.name]: e.target.value });
    setCommentFormErrors({ ...commentFormErrors, [e.target.name]: '' });
  }


  // fetch all ticket types 
  async function fetchAllTicketTypes(){ 
    try{ 
      let response = await fetch_all_ticket_types(); 
      if(response.data.code === 200){ 
        let data=  response.data?.data?.ticketTypes;
        const updatedTicketTypes = data.map(ticketType => ({ value: ticketType.id, label: ticketType.ticket_type }));
        setTicketTypes(updatedTicketTypes); 
      } 
    }catch(e){ 
      
    }
  }
  
  function validateForm(){ 
    let errors = {}; 
  
    if (commentForm.files) {
        if (commentForm.files.size > 10000000) {
          errors.files = 'File size is too large. Max size is 10MB';
        }
  
        const allowedExtensions = ['pdf', 'jpeg', 'jpg', 'png','mp4'];
        Array.from(commentForm.files).forEach(file => {
          const fileExtension = file.name.split('.').pop().toLowerCase();        
          if (!allowedExtensions.includes(fileExtension)) {
            errors.files = 'Invalid file type. Only PDF, JPEG, JPG, PNG, TIFF, DOC, DOCX, XLS, XLSX, CSV, TXT, and DCM files are allowed';
          }
        });
  
    }
  
  
    if(!commentForm.comment.trim()){
        errors.comment = 'Comment is required';
    }
    
    return errors; 
  }
  
  const submitComment = async () => { 
    let errors = validateForm(); 
    if(Object.keys(errors).length > 0){ 
        setCommentFormErrors(errors); 
        return; 
    }
  
    setIsSubmitting(true);
  
    let commentDataObj  = {...commentForm, ticketId: ticketId, commentBy: "carenavigator"};

    try{
         let response = await comment_on_ticket(commentDataObj); 
  
         if(response.data.code === 200){
          fetchCommentThread();
          fetchTicketHistory(); 
            // clear the state data 
            setCommentForm({ comment: '', files: '' });
            setCommentFormErrors({ comment: '', files: '' });
            setFileInputs([{ id: 0 }]);
            const fileInputElements = document.querySelectorAll('input[type="file"]');
            fileInputElements.forEach(input => {
                input.value = '';
            }); 
            
            // fetchTicketHistory(); 
  
         }else { 
  
         }
    }catch(error){
        console.error(error);
    } finally {
        setIsSubmitting(false);
    }
  }
  
  const addFileInput = () => {
    setFileInputs([...fileInputs, { id: fileInputs.length }]);
  };
  
  const removeFileInput = (inputId) => {
    setFileInputs(fileInputs.filter(input => input.id !== inputId));
    
    const updatedFiles = Array.from(commentForm.files || []);
    updatedFiles[inputId] = null;
    const filteredFiles = updatedFiles.filter(file => file !== null);
    setCommentForm({ ...commentForm, files: filteredFiles });
  };

  async function fetchCommentThread() {
    try {
      let response = await fetch_ticket_thread(ticketId);

      if (response.data.code === 200) {
        setTicketThread(response.data?.data?.comments);
      }
    } catch (e) {
      console.log('Some error has occur ed', e);
    }
  }

  function handleModalClose() {
    setShow(false);
  }

  function changeMdoalContentType(e, type) {
    if (type === "show") {
      setModalContentType(type);
    } else if (type === "add") {
      setModalContentType(type);
    }
  }

  function handleShowSingleTicket(ticketId) {
    setTicketId(ticketId);
    setModalContentType("viewSingle");
  }

  // fetch all hcc ticket types
  // fetch all hcc ticket types
  async function fetchAllTickets() {
    setIsLoading(true);
    try {
      let response = await list_all_hcc_tickets({
        perPage: paginationInfo.perPage,
        pageNumber: paginationInfo.currentPage,
        searchQuery: searchTicketId,
        field: sortConfig.field,
        direction: sortConfig.direction,
        status: status,
        membershipNo: membershipNo
      });


      if (response.data.code === 200) {
        setTickets(response.data?.data?.tickets);
        setPaginationInfo({
          ...paginationInfo,
          totalRecords: response.data?.data?.totalEntries,
          totalPages: response.data?.data?.totalPages
        })
      }
    } catch (e) {

    } finally {
      setIsLoading(false);
    }
  }

  function setPerPage(value) {
    setPaginationInfo({
      ...paginationInfo,
      currentPage: 1,
      perPage: value
    });
  }

  const updateSortConfig = (field, direction, isSortable) => {
    if (!isSortable) return;
    setSortConfig({
      field: field,
      direction: direction
    });
  }

  const modalStyle = {
    '--bs-modal-width': '94% !important',
    'minWidth': '1140px !important'
  };

  const getFileIcon = (fileType) => {
    if (fileType.match(/\.(jpg|jpeg|png|gif|webp)$/)) return <Image className="tw-w-6 tw-h-6" />;
    if (fileType.match(/\.(mp4|webm|mov|ogg)$/)) return <Video className="tw-w-6 tw-h-6" />;
    if (fileType.match(/\.pdf$/)) return <FileText className="tw-w-6 tw-h-6" />;
    return <File className="tw-w-6 tw-h-6" />;
  };

  const getFileName = (filepath) => {
    return filepath.split('/').pop();
  };


  function handleClear(){ 
    dispatch(setFilter({ 
      searchTicketId: "", 
      status: "", 
      agent: "", 
      dates: { 
        startDate: moment(), 
        endDate: moment() 
      } 
    }));
    setSearchQuery(""); 
  }


  function handleFilterChange(e){ 
    dispatch(setFilter({
      ...filter, [e.target.name]: e.target.value
    })); 
  }

  async function fetchTicketHistory(){ 
    try{ 
      let response = await fetch_ticket_history({ticketId}); 

      if(response?.data?.code === 200){ 
         setTicketHistory(response?.data?.data?.history); 
      }else { 

      }
    }catch(e){ 

    }
  }


  return (
    <Modal
      show={show}
      onHide={handleModalClose}
      size="xl"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
      className={` hcc-modal`}
    >
      <LoaderLoader isLoading={isLoading} />
      <div className='tw-min-w-full tw-max-h-[90vh] tw-overflow-y-auto [&::-webkit-scrollbar]:tw-hidden [-ms-overflow-style:none] [scrollbar-width:none]'>
      <Modal.Header closeButton>
        <div className="d-flex tw-justify-between w-100 px-4 mt-3 ">
          <h4 className="modal-title">
            {
              modalContentType === "show" ? "HCC Tickets" : (modalContentType === "add") ? "Add New HCC Ticket" : ""
            }
          </h4>
          {(modalContentType === "show" && access.add) &&
            <button className='btn btn-primary save-btn' onClick={(e) => changeMdoalContentType(e, "add")} >Add New Ticket <i className='bi bi-plus' /></button>
          }

          {
            (modalContentType === "viewSingle" && access.show) &&
            <button className='btn btn-primary save-btn' onClick={e => setModalContentType("show")} >Back</button>
          }

          {
            (modalContentType === "add" && access.show) &&
            <button className='btn btn-primary save-btn' onClick={e => changeMdoalContentType(e, "show")}>Show all tickets</button>
          }
        </div>
      </Modal.Header>
      <Modal.Body>
        {
          modalContentType === "show" &&

          <div className="hcc-tickets-list">
            <div className='filter '>
            <div className='tw-flex tw-items-center tw-justify-between tw-w-full'>
                    
                    {/* Search Field */}
                    <div className='tw-w-1/2 tw-flex tw-flex-row tw-items-center tw-gap-2'>
                    <div className='tw-w-1/2'>
                    <div className="form-group">
                        <input
                        type="text"
                        name="ticketId"
                        placeholder="Search By Ticket ID"
                        className="form-control input-field-ui w-100"
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                        />
                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="tw-absolute tw-w-6 tw-top-2.5 tw-right-2" alt="" />
                    </div>
                    </div>

                    {/* Status Dropdown */}
                    <div className='tw-w-1/2'>
                    <select
                        name="status"
                        className='form-select input-field-ui w-100'
                        value={status}
                        onChange={e => handleFilterChange(e)}
                    >
                        <option value="">--Select Status--</option>
                        <option value="new">New</option>
                        <option value="assigned ">Assigned</option>
                        <option value="resolved">Resolved</option>
                        <option value="closed">Closed</option>
                        <option value="reopened">Reopened</option>
                    </select>
                    </div>
                    </div>

                    {/* Priority Dropdown */}
                    <div className=''>
                    {/* <select
                        name="agent"
                        className='form-select input-field-ui w-100'
                        value={agent}
                        onChange={e => handleFilterChange(e)}

                    >
                        <option value="">--Select Assigned To--</option>
                        { 
                            allAgents.map((agent, index) => {
                                return <option key={index} value={agent.id}>{agent.name}</option>
                            })
                        }
                    </select> */}
                    </div>

                    {/* Clear Filters Button */}
                    <div className=''>
                    <button className='btn btn-primary btn-primary clear-search-btn w-100' onClick={handleClear}>
                        Clear
                    </button>
                    </div>

                    {/* Export Button */}
                    {/* <div className='col-md-1 px-0'>
                    <button className='btn btn-primary btn-primary clear-search-btn w-100' onClick={handleExport}>
                        Export
                    </button>
                    </div> */}
                </div>

            </div>
            <ServerSideSortableDataTable
              columns={columns}
              data={tickets}
              paginationInfo={paginationInfo}
              setPaginationInfo={setPaginationInfo}
              updateSortConfig={updateSortConfig}
              sortConfig={sortConfig}
              setPerPage={setPerPage}
              isLoading={isLoading}
              maxStringChars={200}
            />
          </div>
        }

        {
          modalContentType === "add" &&

          <AddTicketForm raisedBy={"carenavigator"} fetch_all_ticket_types={fetch_all_ticket_types} add_new_ticket={add_new_ticket} membershipNo={membershipNo} 
            setShow={setShow}
          />
        }

        {
          modalContentType === "viewSingle" &&
          <div className='view-single'>
            <TicketDetails ticketId={ticketId} ticket={ticket} setTicket={setTicket} fetch_single_ticket={fetch_single_ticket} renderingIn="carenavigator" ticketTypes={ticketTypes} update_ticket={update_ticket} fetchTicketHistory={fetchTicketHistory}
            />
            <hr className="tw-my-6 tw-border-dark-200" />

            <div className='ticket-thread'>
              {
                ticketThread.length > 0 &&
                ticketThread.map((item, index) => (
                  <div className='observation-content w-100 px-3'>
                    <div className="comment-wrapper">
                      <div className="comment-info">
                        <div className="comment-author mb-2">
                          <div className="avatar comment-avatar">
                            <img
                              src="https://storage.googleapis.com/ksabupatest/2024/10/30/v9wj7/74g44s3273.webp"
                              alt=""
                              className="user-avatar"
                            />
                          </div>

                          <div className="comment-meta">
                            <span title="Javed Ali">
                              <span>
                                {capitalizeFirstLetter(item.firstname) + ' ' + capitalizeFirstLetter(item.lastname) + ' (' + `${item.commentBy.toLowerCase() === "user" ? 'Business' : item.commentBy.toLowerCase() === "moderator" ? 'Moderator' : item.commentBy.toLowerCase() === "goqii" ? 'GOQii' : item.commentBy}` + ')'}
                              </span>
                            </span>

                            <ul className="meta-group">
                              <li className="meta-data">
                                <time datetime="2024-10-09T14:14:25Z" title="2024-10-09 19:44" data-datetime="relative">
                                  {item.createdTime}
                                </time>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <section className="comment-body">
                          <div className="zd-comment ps-5" dir="auto">
                            <p dir="auto" style={{ wordWrap: 'break-word', width: '100%' }}>{item.comment}</p>
                            {
                              item.attachments?.length ? (
                                <div className="tw-flex tw-flex-row tw-flex-wrap tw-gap-3 tw-mt-3">
                                  {item?.attachments.map((attachment, index) => (
                                    <div key={index} className="tw-max-w-[300px]">
                                      {attachment.toLowerCase().endsWith('.pdf') ? (
                                        <div className="tw-relative tw-group">
                                          <embed
                                            src={attachment}
                                            type="application/pdf"
                                            className="tw-w-full tw-h-[600px]"
                                          />
                                          <div className="tw-absolute tw-top-2 tw-right-2 tw-opacity-0 group-hover:tw-opacity-100 tw-transition-opacity">
                                            <a
                                              href={attachment}
                                              download={attachment.split('/').pop()}
                                              target='_blank'
                                              className="tw-bg-white tw-rounded-full tw-p-2 tw-shadow-md hover:tw-bg-gray-100 tw-transition-colors"
                                            >
                                              <Download className="tw-w-5 tw-h-5 tw-text-gray-600" />
                                            </a>
                                          </div>
                                        </div>
                                      ) : attachment.toLowerCase().match(/\.(mp4|webm|ogg)$/) ? (
                                        <div className="tw-relative tw-group">
                                          <video controls className="tw-w-full tw-h-auto">
                                            <source
                                              src={attachment}
                                              type={`video/${attachment.split('.').pop()}`}
                                            />
                                            Your browser does not support the video tag.
                                          </video>
                                          <div className="tw-absolute tw-top-2 tw-right-2 tw-opacity-0 group-hover:tw-opacity-100 tw-transition-opacity">
                                            <a
                                              href={attachment}
                                              download={attachment.split('/').pop()}
                                              target='_blank'
                                              className="tw-bg-white tw-rounded-full tw-p-2 tw-shadow-md hover:tw-bg-gray-100 tw-transition-colors"
                                            >
                                              <Download className="tw-w-5 tw-h-5 tw-text-gray-600" />
                                            </a>
                                          </div>
                                        </div>
                                      ) : attachment.toLowerCase().match(/\.(jpg|jpeg|png|gif|webp)$/) ? (
                                        <div className="tw-relative tw-group">
                                          <img
                                            src={attachment}
                                            alt="attachment"
                                            className="tw-w-full tw-h-[200px] tw-object-cover"
                                          />
                                          <div className="tw-absolute tw-top-2 tw-right-2 tw-opacity-0 group-hover:tw-opacity-100 tw-transition-opacity">
                                            <a
                                              href={attachment}
                                              download={attachment.split('/').pop()}
                                              target="_blank"
                                              className="tw-bg-white tw-rounded-full tw-p-2 tw-shadow-md hover:tw-bg-gray-100 tw-transition-colors"
                                            >
                                              <Download className="tw-w-5 tw-h-5 tw-text-gray-600" />
                                            </a>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="tw-bg-gray-50 tw-rounded-lg tw-p-4 tw-shadow-sm tw-flex tw-items-center tw-gap-3">
                                          {getFileIcon(attachment.toLowerCase())}
                                          <div className="tw-flex-1 tw-min-w-0">
                                            <p className="tw-text-sm tw-font-medium tw-text-gray-900 tw-truncate">
                                              {getFileName(attachment)}
                                            </p>
                                          </div>
                                          <a
                                            href={attachment}
                                            download
                                            target='_blank'
                                            className="tw-p-2 tw-rounded-full hover:tw-bg-gray-200 tw-transition-colors"
                                          >
                                            <Download className="tw-w-5 tw-h-5 tw-text-gray-600" />
                                          </a>
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              ) : null
                            }
                          </div>
                        </section>
                      </div>
                    </div>
                    <hr />
                  </div>
                ))
              }
            </div>

            <div className='bg-light p-3 input-field-ui'>
              <div className="row align-items-center">
                <div className="col-md-12 mb-3">
                  {/* <label className="form-label">Description <span className="text-danger">*</span></label> */}
                  <textarea
                    name="comment"  
                    placeholder="Comment"
                    rows="3"
                    className="form-control control-input-ui"
                    value={commentForm.comment}
                    onChange={e => handleCommentChange(e)}
                  />
                  {commentFormErrors.comment && <p className="text-danger">{commentFormErrors.comment}</p>}
                </div>


                <div className="">
                  <label className="form-label">Attach screenshots or data that illustrate the observation ( The maximum allowed file upload size is 10 MB )</label>
                  {fileInputs.map((input) => (
                    <div className="d-flex mb-2" key={input.id}>
                      <input
                        type="file"
                        name="files[]"
                        className="form-control input-field-ui"
                        accept=".png, .jpg, .jpeg, .gif, .doc, .docx, .pdf, .csv, .xls, .xlsx"
                        data-input-id={input.id}
                        onChange={e => handleCommentChange(e)}
                      />
                      {fileInputs.length > 1 && (
                        <button
                          type="button"
                          className="btn btn-Cancel ms-2"
                          onClick={() => removeFileInput(input.id)}
                        >
                          <i className="bi bi-trash"></i>
                        </button>
                      )}
                    </div>
                  ))}
                  <button
                    type="button"
                    className="btn btn-primary addNewRow-btn me-2"
                    onClick={addFileInput}
                  >
                    <i className="bi bi-plus"></i>
                    Add New File
                  </button>
                  {commentFormErrors.files && <p className="text-danger">{commentFormErrors.files}</p>}
                </div>


                <div className="col-12 mt-3">
                  <button
                    type="button"
                    id="xlsxSubmit"
                    className="btn btn-success uplaod-file-btn"
                    onClick={e => submitComment(e)}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        Submitting...
                      </>
                    ) : 'Submit'}
                  </button>
                </div>
              </div>
            </div>

            <div className='row mt-4 px-2'>
                      <div className='col-md-12'>
                        <h5 className='dashboard-graph-title mb-3' style={{ justifyContent: 'flex-start' }}>
                          Ticket History
                        </h5>
                        <div className="table-responsive mb-3">

                          <table className="table table-striped mb-0">
                            <thead>
                              <tr>
                                <th style={{ backgroundColor: "rgb(212, 230, 239)", fontSize: "14px", fontWeight: "500" }}>Date Modified</th>
                                <th style={{ backgroundColor: "rgb(212, 230, 239)", fontSize: "14px", fontWeight: "500" }}>Username</th>
                                <th style={{ backgroundColor: "rgb(212, 230, 239)", fontSize: "14px", fontWeight: "500" }}>Comment</th>
                              </tr>
                            </thead>  
                            <tbody>
                              {ticketHistory.map((history, index) => (
                                <tr key={index}>
                                  <td style={{ fontSize: "13px" }}>{history.createdTime}</td>
                                  <td style={{ fontSize: "13px" }}>
                                    {history.name}  {`( ${history.commentBy === 'hcc_agent' ? 'HCC Agent' : 'Carenavigator'} )`}
                                  </td>
                                  <td style={{ fontSize: "13px" }}>{history.comments}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>

                        </div>
                      </div>
                    </div>

          </div>
        }
      </Modal.Body>
      </div>
    </Modal>
  )
}

export default HccTicketModal; 
