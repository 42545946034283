import React, { useState } from 'react';
import './UploadMemberDetailsPage.css';
import Sidenavmenubar from '../componenents/Sidenavmenubar';
import Navbar from '../componenents/Navbar';
import ContentRCMTeamDashboard from '../componenents/ContentRCMTeamDashboard';


function ReportsPage() {  
  const [title, setTitle] = useState('Data Migration Reports');

  return (
    <>
      <ContentRCMTeamDashboard/>
    </>
  );
}


export default ReportsPage;
