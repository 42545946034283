import React from 'react'

const PageDescription = ({image, text}) => {
  return (
    <div className="row align-items-center pb-3">
    <div className='col-12'>
    <div className='reports-programs'>
      <div className=" row align-items-center">
        <div className="col-md-auto">
                <img src={image} alt="Invited Members icon" style={{height:"140px"}}/>
         </div>
         <div className="col-md">
            <p className="mb-0">{text}</p>
          </div>
        </div>
    </div>
    </div>
    
  </div>
  )
}

export default PageDescription; 
